/* global EM */
import React, { Component } from 'react';
import { Label, FormGroup, FormText } from 'reactstrap';
import Creatable from 'react-select/creatable';

export default class SettingsListInput extends Component {
    constructor(props){
        super(props);

        this.settingObj = EM.settings.findByKey(props.setting);        
        let value = null;
        if (this.settingObj) value = this.settingObj.Value;
        let valuesForList = value ? value.split(',').map((item) => { return this.createOption(item) }) : [];

        this.state = {
            inputValue: '',
            value: valuesForList,
            hiddenColumns:[],
            error: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    handleChange = (value, actionMeta) => {    
        if(this.props.setting === "PinnedColumns"){
            let checkHiddenValue = this.state?.hiddenColumns?.filter(x => x === this.state.inputValue);
            if(checkHiddenValue?.length>0){
                this.setState({error:true,inputValue : ''});
                return;
            }
        }
        this.saveSetting(value);
        this.setState({ value });
    };

    handleInputChange(inputValue){
        this.setState({error: false})
        if (this.props.characterLimit){
            if (inputValue.length > this.props.characterLimit){
                return;
            }
        }

        this.setState({ inputValue });
    };

    createOption = (label) => ({
        label,
        value: label,
    });

    saveSetting(values){
        let valueStr = '';
        if (values){
            let valueBits = values.map(item => { return item.value });
            valueStr = valueBits.join(',');
        }
        if (this.settingObj){
            let newObj = Object.assign({}, this.settingObj, { Value: valueStr });
            EM.settings.update(this.settingObj, newObj);
        }else{
            EM.settings.create({
                Key: this.props.setting,
                Value: valueStr
            }).then((newItem) => {
                this.settingObj = newItem.data;
            });        
        }
    }

    handleKeyDown = (event) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':                
                let newState = {
                    inputValue: '',
                    value: [...(value||[]), this.createOption(inputValue)],
                };
                if(this.props.setting === "PinnedColumns"){
                    let checkHiddenValue = this.state?.hiddenColumns?.filter(x => x === this.state.inputValue);
                    if(checkHiddenValue?.length>0){
                        this.setState({error:true,inputValue : ''});
                        return;
                    }
                }
                this.saveSetting(newState.value);
                this.setState(newState);
                event.preventDefault();
                break;
            default: 
                return;
        }
    };
   componentDidMount(){
        let hiddenColumnsData = [];
        let hiddenColumnSetting = EM.getSetting('HiddenColumns');
            if (hiddenColumnSetting) {
                hiddenColumnSetting.split(',').map((col) => {
                    return hiddenColumnsData.push(col.trim());
                })
            }
        this.setState({ hiddenColumns: hiddenColumnsData });
    }
    
    render() {
        const { setting, classes } = this.props;
        let keyTbase = 'settings.generalSettings.' + setting.toLowerCase() + '.';

        const { inputValue, value } = this.state;

        return (
            <>
            <FormGroup className={classes}>
                <Label>{EM.t(keyTbase + 'label')}:</Label>
                <Creatable
                    components={{
                        DropdownIndicator: null,
                    }}
                    inputValue={inputValue}
                    isClearable={false}
                    isMulti
                    className="multi-select wide"
                    classNamePrefix="multi-select"
                    menuIsOpen={false}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    placeholder={EM.t('util.filtering.typehere')}
                    value={value}
                />
                <FormText>{EM.t(keyTbase + 'description')}</FormText>
            </FormGroup>
            <FormText className='errorColor'>{this.state.error?"You Can't Pinned Hidden Value":""}</FormText>
            </>
        );
    }
};